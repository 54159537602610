import React, { useContext, useEffect, useState } from "react";
import ApiContext from "../../../../contexts/api/ApiContext";
import ShopBase from "./ShopBase";
import styles from "./ShopOverview.module.scss";
import ProgressBar from "../../../ui/ProgressBar/ProgressBar";
import { useTranslation } from "react-i18next";
import { CD } from "../../../../data/interfaces";
import { Link } from "react-router-dom";
import { formatter } from "../../../../utils/currency";

const ShopOverview: React.FC = () => {
    const { cds } = useContext(ApiContext);
    const { t } = useTranslation("shop");
    const [cdData, setCDData] = useState<CD[]>([]);

    useEffect(() => {
        if (cds.isFetching || !cds.data.success) return;
        setCDData(cds.data.data.data);
    }, [cds]);

    return (
        <ShopBase>
            {cds.isFetching && <ProgressBar.Blue className={styles.progressbar} visible indeterminate />}
            {!cds.isFetching && cds.data.success && cds.data.data.data.length === 0 && <i>{t("no-cds")}</i>}
            {cdData.length !== 0 && (
                <div className={styles.overview}>
                    {cdData.map(cd => (
                        <Link to={`/shop/${cd.id}`} className={styles.wrapper} key={cd.id}>
                            <div className={styles.column}>
                                <div className={styles.image}>
                                    <img
                                        src={cd.image}
                                        onError={e => e.currentTarget.parentElement?.classList.add(styles.hidden)}
                                        alt={cd.title}
                                    />
                                    <div className={styles.title}>{cd.title}</div>
                                </div>
                            </div>
                            <div className={styles.column}>
                                <div className={styles.title}>{cd.title}</div>
                                <div className={styles.amount}>{formatter.format(cd.price)}</div>
                                <div className={styles.additional}>{t("ex-shipping-costs")}</div>
                            </div>
                        </Link>
                    ))}
                </div>
            )}
        </ShopBase>
    );
};

export default ShopOverview;
