import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styles from "./MenuItem.module.scss";
import classNames from "classnames";
import { useAsync } from "react-use";
import AdminContext from "../../../../contexts/admin/AdminContext";

interface Props {
    // ID for the menu-item
    id: string;
    // The route to go to if the item is clicked
    route: string;
    // The name to show in the menu
    children: string;
    // Whether shopping-cart should be shown on the page
    withCart?: boolean;
}

const MenuItem: React.FC<Props> = props => {
    const { activeMenuItem, setActiveMenuItem, addMenuItem } = useContext(AdminContext);

    useAsync(async () => {
        addMenuItem({
            id: props.id,
            route: props.route,
            name: props.children,
        });
    });

    return (
        <li
            className={classNames(styles.item, { [styles.active]: activeMenuItem === props.id })}
            onClick={() => setActiveMenuItem(props.id)}
        >
            <Link to={props.route}>
                <span>{props.children}</span>
            </Link>
        </li>
    );
};

export default MenuItem;
