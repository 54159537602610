import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import usePage from "../../../../contexts/menu/MenuConsumer";
import Page from "../../../ui/Page/Page";
import styles from "./Contact.module.scss";
import TextInput from "../../../ui/TextInput/TextInput";
import TextArea from "../../../ui/TextArea/TextArea";
import Button from "../../../ui/Button/Button";
import ApiContext from "../../../../contexts/api/ApiContext";
import useSnackBar from "../../../../contexts/snackbar/SnackBarConsumer";

const Contact: React.FC = () => {
    usePage("contact");
    const { t } = useTranslation("contact");
    const [name, setName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [message, setMessage] = useState<string>();
    const [loading, setLoading] = useState(false);
    const { sendMessage } = useContext(ApiContext);
    const addSnackBar = useSnackBar();

    /**
     * Try send a message.
     * @param e The form-event.
     */
    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!name || !email || !message) {
            addSnackBar(t("fill-all-fields"), "error");
            return;
        }
        setLoading(true);
        sendMessage(name, email, message).then(success => {
            setLoading(false);
            if (success) {
                addSnackBar(t("message-sent"), "success");
            } else {
                addSnackBar(t("message-not-sent"), "error");
            }
        });
    };

    return (
        <Page className={styles.page}>
            <form className={styles.form} onSubmit={onSubmit}>
                <h1>{t("contact-me")}</h1>
                <TextInput className={styles.input} label={t("name")} onChange={setName} />
                <TextInput className={styles.input} label={t("email")} onChange={setEmail} />
                <TextArea className={styles.input} label={t("message")} onChange={setMessage} />
                <Button loading={loading}>{t("send")}</Button>
                <hr />
                <table className={styles.credits}>
                    <tr>
                        <th>{t("credit-design")}</th>
                        <td>Arno van Liere</td>
                    </tr>
                    <tr>
                        <th>{t("credit-photos")}</th>
                        <td>Jaap &amp; Marja Koole</td>
                    </tr>
                </table>
            </form>
        </Page>
    );
};

export default Contact;
