import React, { useEffect, useRef } from "react";
import styles from "./TextArea.module.scss";
import classNames from "classnames";

interface Props {
    // The label to show in the text-area
    label: string;
    // Executes on change of the input the text-area
    onChange: (input: string) => void;
    defaultValue?: string;
    value?: string;
    className?: string;
    rows?: number;
    resizeY?: boolean;
}

const TextArea: React.FC<Props> = props => {
    const input = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (input.current) {
            input.current.value = props.defaultValue ?? "";
        }
    }, [props.defaultValue]);

    useEffect(() => {
        if (input.current) {
            input.current.value = props.value ?? "";
        }
    }, [props.value]);

    return (
        <div className={classNames(styles.form_control, props.className)}>
            <label>
                <textarea
                    className={classNames({
                        [styles.filled]: props.defaultValue,
                        [styles.resize_y]: props.resizeY,
                    })}
                    rows={props.rows ?? 3}
                    ref={input}
                    onChange={e => {
                        props.onChange(e.target.value);
                        if (e.target.value !== "") input.current?.classList.add(styles.filled);
                        else input.current?.classList.remove(styles.filled);
                    }}
                />
                <span>{props.label}</span>
            </label>
        </div>
    );
};

export default TextArea;
