import React, { useEffect } from "react";

interface Props {
    onChange: (file: string) => void;
    defaultValue?: string;
}

const FileInput: React.FC<Props> = props => {
    // Set default value
    useEffect(() => {
        if (props.defaultValue) {
            props.onChange(props.defaultValue);
        }
        // eslint-disable-next-line
    }, [props.defaultValue]);

    const onChange = (e: React.ChangeEvent) => {
        const files = (e.target as HTMLInputElement)?.files;
        if (!files) return;
        const reader = new FileReader();
        reader.onloadend = function () {
            const res = reader.result?.toString();
            if (!res) return;
            props.onChange(res);
        };
        reader.readAsDataURL(files[0]);
    };

    return <input type="file" onChange={onChange} accept="image/*" />;
};

export default FileInput;
