import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "../../pages/Login/Login";
import AdminProvider from "../../../../contexts/admin/AdminProvider";
import Dashboard from "../Dashboard/Dashboard";
import ApiProvider from "../../../../contexts/api/ApiProvider";

const AppRouter: React.FC = () => {
    return (
        <ApiProvider loadImages={false}>
            <AdminProvider>
                <Router basename="/admin">
                    <Switch>
                        <Route path="/login" component={Login} />
                        <Route component={Dashboard} />
                    </Switch>
                </Router>
            </AdminProvider>
        </ApiProvider>
    );
};

export default AppRouter;
