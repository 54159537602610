import React, { useContext } from "react";
import ShopBase from "./ShopBase";
import ApiContext from "../../../../contexts/api/ApiContext";
import { CD } from "../../../../data/interfaces";
import { Redirect } from "react-router-dom";
import ProgressBar from "../../../ui/ProgressBar/ProgressBar";
import styles from "./ShopDetail.module.scss";
import { useTranslation } from "react-i18next";
import Button from "../../../ui/Button/Button";
import { formatter } from "../../../../utils/currency";
import CartContext from "../../../../contexts/cart/CartContext";
import useSnackBar from "../../../../contexts/snackbar/SnackBarConsumer";

interface Props {
    match?: {
        params: {
            id: string;
        };
    };
}

/**
 * Check if an array contains a CDs with a specific id.
 * @param cds The array to search in.
 * @param id  The id to search for.
 * @return    Whether the CDs is found or not.
 */
const getCD = (cds: CD[], id: string): CD | undefined => {
    for (const cd of cds) {
        if (cd.id === Number(id)) return cd;
    }
    return undefined;
};

const ShopDetail: React.FC<Props> = props => {
    const { t } = useTranslation("shop");
    const { cds } = useContext(ApiContext);
    const { addToCart } = useContext(CartContext);
    const addSnackBar = useSnackBar();

    if (!props.match || (!cds.isFetching && (!cds.data.success || !getCD(cds.data.data.data, props.match.params.id)))) {
        return <Redirect to="/shop" />;
    }
    const cd = !cds.isFetching && cds.data.success ? getCD(cds.data.data.data, props.match.params.id) : undefined;

    return (
        <ShopBase>
            {!cd && <ProgressBar.Blue className={styles.progressbar} visible indeterminate />}
            {cd && (
                <div className={styles.wrapper}>
                    <div className={styles.column}>
                        <div className={styles.image}>
                            <img
                                src={cd.image}
                                alt={cd.title}
                                onError={e => e.currentTarget.parentElement?.classList.add(styles.hidden)}
                            />
                            <div className={styles.title}>{cd.title}</div>
                        </div>
                        <div className={styles.detail}>
                            <div className={styles.price}>
                                <span className={styles.amount}>{formatter.format(cd.price)}</span>
                                <span className={styles.additional}>{t("ex-shipping-costs")}</span>
                            </div>
                            <Button
                                onClick={() => {
                                    addToCart(cd.id);
                                    addSnackBar(
                                        t("cd-added", {
                                            name: cd?.title,
                                        }),
                                        "success"
                                    );
                                }}
                            >
                                {t("add-shopping-cart")}
                            </Button>
                        </div>
                    </div>
                    <div className={styles.column}>
                        <div className={styles.title}>{cd.title}</div>
                        <div className={styles.description}>{cd.description}</div>
                    </div>
                </div>
            )}
        </ShopBase>
    );
};

export default ShopDetail;
