import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../ui/Header/Header";
import Text from "../../../ui/Text/Text";
import Page from "../../../ui/Page/Page";
import styles from "./Home.module.scss";
import usePage from "../../../../contexts/menu/MenuConsumer";
import profile from "../../../../assets/images/008.jpg";
import ApiContext from "../../../../contexts/api/ApiContext";
import ApiPage from "../../../ui/ApiPage/ApiPage";

const Home: React.FC = () => {
    usePage("home");
    const { t } = useTranslation("home");
    const { pageHome } = useContext(ApiContext);

    return (
        <Page className={styles.page}>
            <Header img="004" className={styles.header}>
                <h1>{t("iddo-van-der-giessen")}</h1>
                <h3>{t("musician")}</h3>
            </Header>
            <Text
                rightImg={{
                    src: profile,
                    alt: t("profile"),
                }}
            >
                <ApiPage data={pageHome} />
            </Text>
        </Page>
    );
};

export default Home;
