import React, { useCallback, useContext, useMemo, useReducer, useState } from "react";
import AdminContext, { AdminContextType, initialContext } from "./AdminContext";
import AdminReducer, { AdminReducerActions } from "./AdminReducer";
import { useAsync } from "react-use";
import { httpGet } from "../../utils/get";
import { GetSession } from "../../data/api-response";
import { httpPost } from "../../utils/post";
import ApiContext from "../api/ApiContext";
import AdminMenuItemType from "./AdminMenuType";

const AdminProvider: React.FC = props => {
    const [state, dispatch] = useReducer(AdminReducer, initialContext);
    const [token, setTokenState] = useState<string>();
    const { apiServer } = useContext(ApiContext);

    function setToken(token: string | undefined) {
        if (token === undefined) {
            setTokenState(undefined);
            localStorage.removeItem("token");
        } else {
            setTokenState(token);
            localStorage.setItem("token", token);
        }
    }

    /**
     * Set the value for "verified" in the context.
     * @param verified The value to set.
     */
    const setVerified = useCallback((verified: boolean) => {
        dispatch({
            type: AdminReducerActions.SET_VERIFIED,
            payload: verified,
        });
    }, []);

    /**
     * Set the value for "isVerifying" in the context.
     * @param verifying The value to set.
     */
    const setIsVerifying = useCallback((verifying: boolean) => {
        dispatch({
            type: AdminReducerActions.SET_IS_VERIFYING,
            payload: verifying,
        });
    }, []);

    /**
     * Sign a user in.
     * @param username The username of the user to sign in.
     * @param password The password of the user to sign in.
     * @return         Promise indicating the success of signing in.
     */
    const signIn = useCallback(
        async (username: string, password: string): Promise<boolean> => {
            const res = await httpPost<GetSession>(`${apiServer}/auth`, { username, password });
            if (res.success) {
                setIsVerifying(false);
                setVerified(true);
                setToken(res.data.data.token);
            } else {
                setIsVerifying(false);
                setVerified(false);
            }
            return res.success;
        },
        [apiServer, setIsVerifying, setVerified]
    );

    /**
     * Sign a user out.
     */
    const signOut = useCallback(() => {
        setVerified(false);
        setIsVerifying(false);
        httpPost(`${apiServer}/auth/delete`, {}, token).then();
        setToken(undefined);
    }, [token, setIsVerifying, setVerified, apiServer]);

    /**
     * Set the active menu-item in the admin-dashboard.
     * @param activeNav The id of the item to set to active.
     */
    const setActiveMenuItem = useCallback((activeNav: string) => {
        dispatch({
            type: AdminReducerActions.SET_ACTIVE_MENU_ITEM,
            payload: activeNav,
        });
    }, []);

    /**
     * Add a menu-item in the menu of the admin-dashboard.
     * @param menuItem The details of the item to add.
     */
    const addMenuItem = useCallback((menuItem: AdminMenuItemType) => {
        dispatch({
            type: AdminReducerActions.ADD_MENU_ITEM,
            payload: menuItem,
        });
    }, []);

    // Verify user when the application is loaded
    useAsync(async () => {
        const localToken = localStorage.getItem("token");
        if (!localToken) {
            setVerified(false);
            setIsVerifying(false);
        } else {
            const res = await httpGet<GetSession>(`${apiServer}/auth`, localToken);
            setVerified(res.success);
            setIsVerifying(false);
            if (!res.success) {
                setToken(undefined);
            } else {
                setToken(localToken);
            }
        }
    }, []);

    const contextValue = useMemo((): AdminContextType => {
        return {
            verified: state.verified,
            setVerified: setVerified,
            isVerifying: state.isVerifying,
            setIsVerifying: setIsVerifying,
            signIn: signIn,
            signOut: signOut,

            token: token ?? "",

            activeMenuItem: state.activeMenuItem,
            setActiveMenuItem: setActiveMenuItem,
            menuItems: state.menuItems,
            addMenuItem: addMenuItem,
        };
    }, [
        state.verified,
        setVerified,
        state.isVerifying,
        setIsVerifying,
        signIn,
        signOut,
        state.activeMenuItem,
        setActiveMenuItem,
        state.menuItems,
        addMenuItem,
        token,
    ]);

    return <AdminContext.Provider value={contextValue}>{props.children}</AdminContext.Provider>;
};

export default AdminProvider;
