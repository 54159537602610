import React from "react";
import i18next from "i18next";

/**
 * Wrap a path in a SVG-element. Add a title.
 * @param title The key of the title to add.
 * @param path  The path to wrap <svg> around.
 * @return      Result after wrapping / adding title.
 */
const svg = (title: string, path: JSX.Element): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" aria-labelledby="title">
            <title id="title">{i18next.t(`icons:${title}`)}</title>
            {path}
        </svg>
    );
};

const icons = {
    calendar: (color: string) =>
        svg(
            "calendar",
            <>
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                    fill={color}
                    d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-7 5h5v5h-5z"
                />
            </>
        ),
    cart: (color: string) =>
        svg(
            "shopping-cart",
            <>
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                    fill={color}
                    d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
                />
            </>
        ),
    clock: (color: string) =>
        svg(
            "clock",
            <>
                <path
                    fill={color}
                    d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                />
                <path fill={color} d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
            </>
        ),
    close: (color: string) =>
        svg(
            "close",
            <>
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                    fill={color}
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                />
            </>
        ),
    edit: (color: string) =>
        svg(
            "edit",
            <>
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                    fill={color}
                    d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM5.92 19H5v-.92l9.06-9.06.92.92L5.92 19zM20.71 5.63l-2.34-2.34c-.2-.2-.45-.29-.71-.29s-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41z"
                />
            </>
        ),
    location: (color: string) =>
        svg(
            "location",
            <>
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                    fill={color}
                    d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"
                />
                <circle fill={color} cx="12" cy="9" r="2.5" />
            </>
        ),
    search: (color: string) =>
        svg(
            "search",
            <>
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                    fill={color}
                    d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
                />
            </>
        ),
    trash: (color: string) =>
        svg(
            "trash",
            <>
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                    fill={color}
                    d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"
                />
            </>
        ),
};

export default icons;
