import React from "react";
import ProgressBar from "../../../ui/ProgressBar/ProgressBar";
import styles from "./Loading.module.scss";
import { useTranslation } from "react-i18next";

const Loading: React.FC = () => {
    const { t } = useTranslation("adminGeneral");

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <ProgressBar.Blue visible indeterminate />
                <div className={styles.text}>{t("loading")}</div>
            </div>
        </div>
    );
};

export default Loading;
