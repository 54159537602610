import { createContext } from "react";

export interface CartContextType {
    // ID's of the cd's in the shopping-cart
    inCart: number[];
    // Add item to shopping-cart
    addToCart: (id: number, toCache?: boolean) => void;
    // Remove an item from the shopping-cart
    removeFromCart: (id: number) => void;
    // Clear he shopping-cart
    clearCart: () => void;
}

export const initialContext: CartContextType = {
    inCart: [],
    addToCart: () => {},
    removeFromCart: () => {},
    clearCart: () => {},
};

const CartContext = createContext(initialContext);

export default CartContext;
