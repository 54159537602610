import { useContext, useEffect, useRef } from "react";
import { useAsync } from "react-use";
import i18n from "../../i18n";
import AdminMenuItemType from "./AdminMenuType";
import AdminContext from "./AdminContext";

/**
 * Set the active-menu-item and the page title.
 * @param id The id of the active page.
 * @return   The details of the active page.
 */
const useAdminPage = (id: string): AdminMenuItemType => {
    const { setActiveMenuItem, menuItems, activeMenuItem } = useContext(AdminContext);
    let page = useRef<AdminMenuItemType>(menuItems[id]);
    useEffect(() => {
        document.title = i18n.t("page-title", { pageTitle: menuItems[activeMenuItem]?.name });
    }, [menuItems, activeMenuItem]);
    useAsync(async () => {
        setActiveMenuItem(id);
    });
    return page.current;
};

export default useAdminPage;
