import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import usePage from "../../../../contexts/menu/MenuConsumer";
import Page from "../../../ui/Page/Page";
import Header from "../../../ui/Header/Header";
import Text from "../../../ui/Text/Text";
import img007 from "../../../../assets/images/007.jpg";
import ApiContext from "../../../../contexts/api/ApiContext";
import ApiPage from "../../../ui/ApiPage/ApiPage";

const Biography: React.FC = () => {
    usePage("biography");
    const { t } = useTranslation("biography");
    const { pageBio } = useContext(ApiContext);

    return (
        <Page>
            <Header img="001" />
            <Text
                rightImg={{
                    src: img007,
                    alt: t("profile-picture"),
                    extra: "© Annemiek Kool",
                }}
            >
                <ApiPage data={pageBio} />
            </Text>
        </Page>
    );
};

export default Biography;
