import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import agendaDe from "./locales/pages/agenda/de.json";
import agendaEn from "./locales/pages/agenda/en.json";
import agendaNl from "./locales/pages/agenda/nl.json";
import biographyDe from "./locales/pages/biography/de.json";
import biographyEn from "./locales/pages/biography/en.json";
import biographyNl from "./locales/pages/biography/nl.json";
import cartDe from "./locales/pages/cart/de.json";
import cartEn from "./locales/pages/cart/en.json";
import cartNl from "./locales/pages/cart/nl.json";
import shopDe from "./locales/pages/shop/de.json";
import shopEn from "./locales/pages/shop/en.json";
import shopNl from "./locales/pages/shop/nl.json";
import contactDe from "./locales/pages/contact/de.json";
import contactEn from "./locales/pages/contact/en.json";
import contactNl from "./locales/pages/contact/nl.json";
import homeDe from "./locales/pages/home/de.json";
import homeEn from "./locales/pages/home/en.json";
import homeNl from "./locales/pages/home/nl.json";
import mediaDe from "./locales/pages/media/de.json";
import mediaEn from "./locales/pages/media/en.json";
import mediaNl from "./locales/pages/media/nl.json";
import globalDe from "./locales/global/de.json";
import globalEn from "./locales/global/en.json";
import globalNl from "./locales/global/nl.json";
import menuDe from "./locales/menu/de.json";
import menuEn from "./locales/menu/en.json";
import menuNl from "./locales/menu/nl.json";
import iconsDe from "./locales/icons/de.json";
import iconsEn from "./locales/icons/en.json";
import iconsNl from "./locales/icons/nl.json";

// Admin locales
import adminCdDe from "./locales/admin/cd/de.json";
import adminCdEn from "./locales/admin/cd/en.json";
import adminCdNl from "./locales/admin/cd/nl.json";
import adminEventsDe from "./locales/admin/events/de.json";
import adminEventsEn from "./locales/admin/events/en.json";
import adminEventsNl from "./locales/admin/events/nl.json";
import adminGeneralDe from "./locales/admin/general/de.json";
import adminGeneralEn from "./locales/admin/general/en.json";
import adminGeneralNl from "./locales/admin/general/nl.json";
import adminLoginDe from "./locales/admin/login/de.json";
import adminLoginEn from "./locales/admin/login/en.json";
import adminLoginNl from "./locales/admin/login/nl.json";
import adminMediaDe from "./locales/admin/media/de.json";
import adminMediaEn from "./locales/admin/media/en.json";
import adminMediaNl from "./locales/admin/media/nl.json";
import adminMenuDe from "./locales/admin/menu/de.json";
import adminMenuEn from "./locales/admin/menu/en.json";
import adminMenuNl from "./locales/admin/menu/nl.json";
import adminPagesDe from "./locales/admin/pages/de.json";
import adminPagesEn from "./locales/admin/pages/en.json";
import adminPagesNl from "./locales/admin/pages/nl.json";
import adminOrdersDe from "./locales/admin/orders/de.json";
import adminOrdersEn from "./locales/admin/orders/en.json";
import adminOrdersNl from "./locales/admin/orders/nl.json";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "nl",
        debug: process.env.NODE_ENV === "development",

        resources: {
            de: {
                agenda: agendaDe,
                biography: biographyDe,
                shop: shopDe,
                contact: contactDe,
                home: homeDe,
                media: mediaDe,
                global: globalDe,
                menu: menuDe,
                icons: iconsDe,
                cart: cartDe,
                adminCd: adminCdDe,
                adminEvents: adminEventsDe,
                adminGeneral: adminGeneralDe,
                adminLogin: adminLoginDe,
                adminMedia: adminMediaDe,
                adminMenu: adminMenuDe,
                adminPages: adminPagesDe,
                adminOrders: adminOrdersDe,
            },
            en: {
                agenda: agendaEn,
                biography: biographyEn,
                shop: shopEn,
                contact: contactEn,
                home: homeEn,
                media: mediaEn,
                global: globalEn,
                menu: menuEn,
                icons: iconsEn,
                cart: cartEn,
                adminCd: adminCdEn,
                adminEvents: adminEventsEn,
                adminGeneral: adminGeneralEn,
                adminLogin: adminLoginEn,
                adminMedia: adminMediaEn,
                adminMenu: adminMenuEn,
                adminPages: adminPagesEn,
                adminOrders: adminOrdersEn,
            },
            nl: {
                agenda: agendaNl,
                biography: biographyNl,
                shop: shopNl,
                contact: contactNl,
                home: homeNl,
                media: mediaNl,
                global: globalNl,
                menu: menuNl,
                icons: iconsNl,
                cart: cartNl,
                adminCd: adminCdNl,
                adminEvents: adminEventsNl,
                adminGeneral: adminGeneralNl,
                adminLogin: adminLoginNl,
                adminMedia: adminMediaNl,
                adminMenu: adminMenuNl,
                adminPages: adminPagesNl,
                adminOrders: adminOrdersNl,
            },
        },
        ns: [
            "agenda",
            "biography",
            "shop",
            "contact",
            "home",
            "media",
            "global",
            "menu",
            "icons",
            "cart",
            "adminCd",
            "adminEvents",
            "adminGeneral",
            "adminLogin",
            "adminMedia",
            "adminMenu",
            "adminPages",
            "adminOrders",
        ],
        defaultNS: "global",

        interpolation: {
            escapeValue: false,
            formatSeparator: ",",
        },

        returnObjects: true,

        react: {
            wait: true,
        },
    });

export default i18n;
