import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import img001 from "../../../assets/images/001.jpg";
import img002 from "../../../assets/images/002.jpg";
import img003 from "../../../assets/images/003.jpg";
import img004 from "../../../assets/images/004.jpg";
import img005 from "../../../assets/images/005.jpg";
import img006 from "../../../assets/images/006.jpg";
import img007 from "../../../assets/images/007.jpg";
import styles from "./Header.module.scss";

interface Props {
    // Optional className to add
    className?: string;
    // Image to show
    img: "001" | "002" | "003" | "004" | "005" | "006" | "007";
}

const Header: React.FC<Props> = props => {
    const { t } = useTranslation("global");
    let src;
    switch (props.img) {
        case "001":
            src = img001;
            break;
        case "002":
            src = img002;
            break;
        case "003":
            src = img003;
            break;
        case "004":
            src = img004;
            break;
        case "005":
            src = img005;
            break;
        case "006":
            src = img006;
            break;
        case "007":
            src = img007;
    }

    return (
        <header className={classNames(props.className, styles.header)}>
            <img
                className={classNames(styles.img, { [styles.five]: props.img === "005" })}
                src={src}
                alt={t("background")}
            />
            {props.children}
        </header>
    );
};

export default Header;
