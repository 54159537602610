import React from "react";
import usePage from "../../../../contexts/menu/MenuConsumer";
import Page from "../../../ui/Page/Page";
import Header from "../../../ui/Header/Header";
import styles from "./ShopBase.module.scss";

const ShopBase: React.FC = props => {
    usePage("shop");

    return (
        <Page>
            <Header img="004" />
            <div className={styles.content}>{props.children}</div>
        </Page>
    );
};

export default ShopBase;
