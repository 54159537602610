import React from "react";
import classNames from "classnames";
import styles from "./Page.module.scss";

interface Props {
    // Optional className to add
    className?: string;
}

const Page: React.FC<Props> = props => {
    return <section className={classNames(props.className, styles.wrapper)}>{props.children}</section>;
};

export default Page;
