import React, { useContext, useState } from "react";
import styles from "./Menu.module.scss";
import MenuItem from "./MenuItem";
import i18n from "../../../../i18n";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import MenuContext from "../../../../contexts/menu/MenuContext";
import Icon from "../../../ui/Icon/Icon";
import ApiContext from "../../../../contexts/api/ApiContext";
import { CD } from "../../../../data/interfaces";
import { formatter } from "../../../../utils/currency";
import CartContext from "../../../../contexts/cart/CartContext";
import { Link } from "react-router-dom";
import { contains } from "../../../../utils/array";

const Menu: React.FC = () => {
    const { t } = useTranslation("menu");
    const { cds } = useContext(ApiContext);
    const { activeMenuItem, menuItems } = useContext(MenuContext);
    const { inCart } = useContext(CartContext);
    const [open, setOpen] = useState(false);
    const languages: { [key: string]: string } = {
        de: "DE",
        en: "EN",
        nl: "NL",
    };

    /**
     * Switch the language of the app and trigger re-render.
     * @param lng The language to switch to.
     */
    const switchLanguage = async (lng: string) => {
        await i18n.changeLanguage(lng);
    };

    /**
     * Calculate the total amount in the shopping cart.
     * @param cds The CDs's which are fetched from the API.
     * @return    The total amount.
     */
    const getTotalInCart = (cds: CD[]): number => {
        let total = 0;
        for (const cart of inCart) {
            for (const cd of cds) {
                if (cd.id === cart) {
                    total += cd.price;
                    break;
                }
            }
        }
        return total;
    };

    const toggle = () => {
        setOpen(!open);
        if (!open) {
            document.querySelector("html")?.classList.add("menu-open");
        } else {
            document.querySelector("html")?.classList.remove("menu-open");
        }
    };

    return (
        <>
            <div className={styles.hamburger_wrapper}>
                <div className={classNames(styles.hamburger, { [styles.open]: open })} onClick={toggle}>
                    <span />
                    <span />
                    <span />
                </div>
            </div>
            <nav className={classNames(styles.nav, { [styles.visible]: open })}>
                <ul className={styles.languages}>
                    {Object.keys(languages).map(lng => (
                        <li
                            key={lng}
                            onClick={() => switchLanguage(lng)}
                            className={classNames({
                                [styles.active]:
                                    i18n.language === lng ||
                                    (!contains(Object.keys(languages), i18n.language) && lng === "nl"),
                            })}
                        >
                            {languages[lng]}
                        </li>
                    ))}
                </ul>
                <ul>
                    <MenuItem id="home" route="/" onClick={toggle}>
                        {t("home")}
                    </MenuItem>
                    <MenuItem id="agenda" route="/agenda" onClick={toggle}>
                        {t("agenda")}
                    </MenuItem>
                    <MenuItem id="biography" route="/biography" onClick={toggle}>
                        {t("biography")}
                    </MenuItem>
                    <MenuItem id="media" route="/media" onClick={toggle}>
                        {t("media")}
                    </MenuItem>
                    <MenuItem id="shop" route="/shop" withCart onClick={toggle}>
                        {t("shop")}
                    </MenuItem>
                    <MenuItem id="contact" route="/contact" onClick={toggle}>
                        {t("contact")}
                    </MenuItem>
                    {(menuItems[activeMenuItem]?.withCart || inCart.length !== 0) && (
                        <li className={styles.shopping_cart} onClick={toggle}>
                            <Link to="/cart">
                                <Icon.LightRed className={styles.icon} name="cart" />
                                <div className={styles.amount}>
                                    {!cds.isFetching && cds.data.success
                                        ? formatter.format(getTotalInCart(cds.data.data.data))
                                        : formatter.format(0)}
                                </div>
                            </Link>
                        </li>
                    )}
                </ul>
            </nav>
        </>
    );
};

export default Menu;
