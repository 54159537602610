import React from "react";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import i18n from "../../i18n";
import SnackBarProvider from "../../contexts/snackbar/SnackBarProvider";
import Admin from "../admin/general/AppRouter/AppRouter";
import Client from "../client/general/AppRouter/AppRouter";

const App: React.FC = () => {
    return (
        <I18nextProvider i18n={i18n}>
            <SnackBarProvider>
                <Router>
                    <Switch>
                        <Route path="/admin" component={Admin} />
                        <Route component={Client} />
                    </Switch>
                </Router>
            </SnackBarProvider>
        </I18nextProvider>
    );
};

export default App;
