import React, { useCallback, useMemo, useReducer } from "react";
import SnackBarReducer, { SnackBarReducerActions } from "./SnackBarReducer";
import SnackBarContext, { initialContext } from "./SnackBarContext";
import SnackBar from "../../components/ui/SnackBar/SnackBar";
import styles from "../../components/ui/SnackBar/SnackBar.module.scss";

// How long the animation is for showing/hiding a SnackBar
const ANIMATION_DURATION = 200;
// The total time the SnackBar should be visible (including animation)
const VISIBLE_TIME = 5000;

const SnackBarProvider: React.FC = props => {
    const [state, dispatch] = useReducer(SnackBarReducer, initialContext);

    /**
     * Delete the SnackBar from the DOM with an animation.
     * @return Promise with boolean indicating whether the element was removed or not.
     */
    const deleteLastSnackBar = useCallback(() => {
        // Set the SnackBar as "beingDeleted" (animation is triggered)
        dispatch({ type: SnackBarReducerActions.START_DELETION });
        // Animation is finished after 200ms, so delete the element from the DOM
        setTimeout(() => {
            dispatch({ type: SnackBarReducerActions.DELETE_SNACKBAR });
        }, ANIMATION_DURATION);
    }, []);

    /**3
     * Add an alert.
     * @param message Message to show in the alert.
     * @param type    Type of alert (success, error, warning, neutral)
     * @param action  Possible action to show in SnackBar. Should contain:
     *                 - label   Label for clicking on.
     *                 - onClick Function to execute on click.
     */
    const addSnackBar = useCallback(
        (
            message: string,
            type: "success" | "error" | "warning" | "neutral",
            action?: { label: string; onClick: () => void }
        ) => {
            // Add a new SnackBar after the old one is removed
            deleteLastSnackBar();
            setTimeout(() => {
                dispatch({
                    type: SnackBarReducerActions.ADD_SNACKBAR,
                    payload: { message, type, beingDeleted: false, action },
                });
            }, ANIMATION_DURATION);
            setTimeout(deleteLastSnackBar, VISIBLE_TIME - ANIMATION_DURATION);
        },
        // eslint-disable-next-line
        []
    );

    const value = useMemo(() => ({ addSnackBar: addSnackBar }), [addSnackBar]);

    return (
        <SnackBarContext.Provider value={value}>
            {props.children}
            <div className={styles.wrapper}>
                {state.snackBar && <SnackBar {...state.snackBar}>{state.snackBar.message}</SnackBar>}
            </div>
        </SnackBarContext.Provider>
    );
};
export default SnackBarProvider;
