import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { MenuProvider } from "../../../../contexts/menu/MenuProvider";
import Agenda from "../../pages/Agenda/Agenda";
import Biography from "../../pages/Biography/Biography";
import Media from "../../pages/Media/Media";
import Contact from "../../pages/Contact/Contact";
import Home from "../../pages/Home/Home";
import Menu from "../Menu/Menu";
import CartProvider from "../../../../contexts/cart/CartProvider";
import ShopOverview from "../../pages/Shop/ShopOverview";
import ShopDetail from "../../pages/Shop/ShopDetail";
import Cart from "../../pages/Cart/Cart";
import CartResult from "../../pages/Cart/CartResult";
import ApiProvider from "../../../../contexts/api/ApiProvider";

const AppRouter: React.FC = () => {
    return (
        <ApiProvider>
            <CartProvider>
                <MenuProvider>
                    <Router>
                        <Menu />
                        <Switch>
                            <Route path="/agenda" component={Agenda} />
                            <Route path="/biography" component={Biography} />
                            <Route path="/shop/:id" component={ShopDetail} />
                            <Route path="/shop" component={ShopOverview} />
                            <Route path="/media" component={Media} />
                            <Route path="/contact" component={Contact} />
                            <Route path="/cart/:id" component={CartResult} />
                            <Route path="/cart" component={Cart} />
                            <Route path="/" component={Home} />
                        </Switch>
                    </Router>
                </MenuProvider>
            </CartProvider>
        </ApiProvider>
    );
};

export default AppRouter;
