import { useContext, useEffect, useRef } from "react";
import { useAsync } from "react-use";
import i18n from "../../i18n";
import MenuContext from "./MenuContext";
import MenuItemType from "./MenuItemType";

/**
 * Set the active-menu-item and the page title.
 * @param id The id of the active page.
 * @return   The details of the active page.
 */
const usePage = (id: string): MenuItemType => {
    const { setActiveMenuItem, menuItems, activeMenuItem } = useContext(MenuContext);
    let page = useRef<MenuItemType>(menuItems[id]);
    useEffect(() => {
        document.title = i18n.t("page-title", { pageTitle: menuItems[activeMenuItem]?.name });
    }, [menuItems, activeMenuItem]);
    useAsync(async () => {
        setActiveMenuItem(id);
    });
    return page.current;
};

export default usePage;
