import React, { Fragment, useEffect, useRef, useState } from "react";
import { Event } from "../../../../data/interfaces";
import { useTranslation } from "react-i18next";
import styles from "./AgendaItems.module.scss";
import Icon from "../../../ui/Icon/Icon";
import classNames from "classnames";
import ProgressBar from "../../../ui/ProgressBar/ProgressBar";

interface Props {
    events: Event[];
    isFetching: boolean;
    success: boolean;
}

const AgendaItems: React.FC<Props> = props => {
    const { t } = useTranslation("agenda");
    const [event, setEvent] = useState<Event>();
    const wrapper = useRef<HTMLDivElement>(null);
    const detail = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const body = document.querySelector("body");
        if (body) {
            body.onscroll = () => {
                if (wrapper.current && detail.current) {
                    const wrapperTop = wrapper.current.getBoundingClientRect().top;
                    if (wrapperTop < 24) detail.current.style.top = "24px";
                    else detail.current.style.top = `${wrapperTop}px`;
                }
            };

            // Cleanup on unmount
            return () => {
                body.onscroll = null;
            };
        }
    }, [wrapper, detail]);

    if (props.isFetching) {
        return <ProgressBar.Blue visible indeterminate />;
    }

    if (!props.success) {
        return <i>{t("fetching-failed")}</i>;
    }

    if (props.events.length === 0) {
        return <i>{t("no-events")}</i>;
    }

    let prevYear: number;
    let prevMonth: number;
    return (
        <>
            <div className={styles.event_list} ref={wrapper}>
                {props.events.map(e => {
                    const date = new Date(e.datetime_raw);
                    const withYear = prevYear === undefined || date.getFullYear() !== prevYear;
                    const withMonth = prevMonth === undefined || date.getMonth() !== prevMonth;
                    prevYear = date.getFullYear();
                    prevMonth = date.getMonth();

                    return (
                        <Fragment key={e.id}>
                            {withYear && (
                                <div className={styles.year}>
                                    <span>{date.getFullYear()}</span>
                                </div>
                            )}
                            {withMonth && (
                                <div className={styles.month}>
                                    <span>{t(`months.${date.getMonth()}`)}</span>
                                    <div className={styles.hr} />
                                </div>
                            )}
                            <div className={styles.event_wrapper} onClick={() => setEvent(e)}>
                                <div className={styles.date_wrapper}>
                                    <div>
                                        <div className={styles.day}>{t(`days.${date.getDay()}`)}</div>
                                        <div className={styles.date}>{date.getDate()}</div>
                                    </div>
                                </div>
                                <div className={styles.event}>
                                    <div className={styles.image}>
                                        {
                                            <img
                                                src={e.image}
                                                alt={e.location}
                                                onError={e => {
                                                    e.currentTarget.style.display = "none";
                                                    const parent = e.currentTarget.parentElement;
                                                    if (parent) parent.style.backgroundColor = "darkgrey";
                                                }}
                                            />
                                        }
                                    </div>
                                    <div className={styles.details}>
                                        <div className={styles.title}>{e.title}</div>
                                        <div className={styles.with_img}>
                                            <Icon.DarkBlue className={styles.icon} name="clock" />
                                            <span>{e.time}</span>
                                        </div>
                                        <div className={classNames(styles.with_img, styles.mobile)}>
                                            <Icon.DarkBlue className={styles.icon} name="calendar" />
                                            <span>{e.date}</span>
                                        </div>
                                        <div className={styles.with_img}>
                                            <Icon.DarkBlue className={styles.icon} name="location" />
                                            <span>{e.location}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    );
                })}
            </div>
            <div ref={detail} className={classNames(styles.wrapper, { [styles.visible]: event !== undefined })}>
                <div className={styles.title}>{event?.title}</div>
                <div className={styles.with_img}>
                    <Icon.DarkBlue name="clock" className={styles.img} />
                    <span>{event && <>{event.datetime}</>}</span>
                </div>
                <div className={styles.with_img}>
                    <Icon.DarkBlue name="location" className={styles.img} />
                    <span>{event?.location}</span>
                </div>
                <div className={styles.desc}>{event?.description}</div>
                <Icon.DarkBlue name="close" className={styles.close} onClick={() => setEvent(undefined)} />
            </div>
        </>
    );
};

export default AgendaItems;
