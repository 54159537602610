import React from "react";
import { CartContextType } from "./CartContext";

// Actions which the reducer accepts
export enum CartReducerActions {
    ADD_TO_CART,
    SET_CART,
    CLEAR_CART,
}

interface CartReducerType {
    // Type of action
    type: CartReducerActions;
    // Payload for the action
    payload: any;
}

const CartReducer: React.Reducer<CartContextType, CartReducerType> = (state, reducer): CartContextType => {
    switch (reducer.type) {
        case CartReducerActions.ADD_TO_CART:
            return {
                ...state,
                inCart: [...state.inCart, reducer.payload],
            };
        case CartReducerActions.SET_CART:
            return {
                ...state,
                inCart: reducer.payload,
            };
        case CartReducerActions.CLEAR_CART:
            return {
                ...state,
                inCart: [],
            };
    }
};

export default CartReducer;
