const colors = {
    lightBlue: "#151E3F",
    darkBlue: "#030027",
    white: "#F2F3D9",
    lightRed: "#9A4942",
    red: "#5B0D00",
    offWhite: "#FFFFF9",
};

export default colors;
