import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import styles from "./TextInput.module.scss";

type inputType =
    | "button"
    | "checkbox"
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "file"
    | "float"
    | "hidden"
    | "image"
    | "month"
    | "number"
    | "password"
    | "radio"
    | "range"
    | "reset"
    | "search"
    | "submit"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";

interface Props {
    // Type of input (same as HTML-attribute 'type' on an input)
    type?: inputType;
    // The label corresponding with the input
    label: string;
    // Function to execute when onChange is called
    onChange: (input: string) => void;
    // Value to set on the input
    value?: string;
    // Value to initialize input with
    defaultValue?: string;
    // Whether input should be disabled or not
    disabled?: boolean;
    // Autofocus property for input
    autoFocus?: boolean;
    // Whether input should be smaller than normal.
    condensed?: boolean;
    // Optional classname to add
    className?: string;
    // Optional pattern
    pattern?: string;
}

const TextInput: React.FC<Props> = props => {
    const input = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (input.current) {
            input.current.value = props.defaultValue ?? "";
        }
    }, [props.defaultValue]);

    let type = props.type;
    if (type === "float") {
        type = "number";
    }

    return (
        <div className={classNames(styles.form_control, props.className)}>
            <label>
                <input
                    className={classNames({
                        [styles.filled]: props.disabled || props.defaultValue || props.value,
                    })}
                    ref={input}
                    type={type ?? "text"}
                    onChange={e => {
                        props.onChange(e.target.value);
                        if (e.target.value !== "") input.current?.classList.add(styles.filled);
                        else input.current?.classList.remove(styles.filled);
                    }}
                    pattern={props.pattern}
                    value={props.value}
                    defaultValue={props.defaultValue}
                    disabled={props.disabled}
                    autoFocus={props.autoFocus}
                    step={props.type === "float" ? 0.01 : undefined}
                />
                <span>{props.label}</span>
            </label>
        </div>
    );
};

export default TextInput;
