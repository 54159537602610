import React from "react";
import { MenuContextType } from "./MenuContext";
import MenuItemType from "./MenuItemType";

// Actions which the reducer accepts
export enum MenuReducerActions {
    SET_ACTIVE_MENU_ITEM,
    ADD_MENU_ITEM,
}

interface MenuReducerType {
    // Type of action
    type: MenuReducerActions;
    // Payload for the action
    payload: any;
}

const MenuReducer: React.Reducer<MenuContextType, MenuReducerType> = (state, reducer): MenuContextType => {
    switch (reducer.type) {
        case MenuReducerActions.SET_ACTIVE_MENU_ITEM: {
            return {
                ...state,
                activeMenuItem: reducer.payload,
            };
        }
        case MenuReducerActions.ADD_MENU_ITEM:
            let copy = state.menuItems;
            copy[(reducer.payload as MenuItemType).id] = reducer.payload;
            return {
                ...state,
                menuItems: copy,
            };
    }
};

export default MenuReducer;
