import React from "react";
import { ApiFetch } from "../../../data/api";
import { GetPage } from "../../../data/api-response";
import i18n from "../../../i18n";

interface Props {
    data: ApiFetch<GetPage>;
}

const ApiPage: React.FC<Props> = props => {
    return (
        <>
            {!props.data.isFetching &&
                props.data.data.success &&
                ((i18n.language === "en" && <>{props.data.data.data.data.content_en}</>) ||
                    (i18n.language === "de" && <>{props.data.data.data.data.content_de}</>) ||
                    (i18n.language === "nl" && <>{props.data.data.data.data.content_nl}</>) || (
                        <>{props.data.data.data.data.content_nl}</>
                    ))}
        </>
    );
};

export default ApiPage;
