import React from "react";
import styles from "./SnackBar.module.scss";
import classNames from "classnames";

interface Props {
    // Type of SnackBar
    type: "success" | "error" | "warning" | "neutral";
    // Whether the SnackBar is being deleted or not
    beingDeleted: boolean;
    // Possible action to add to the SnackBar
    action?: {
        // Label of the action
        label: string;
        // Function to execute on click
        onClick: () => void;
    };
}

const SnackBar: React.FC<Props> = props => {
    return (
        <div
            className={classNames(styles.snackbar, {
                [styles.success]: props.type === "success",
                [styles.error]: props.type === "error",
                [styles.warning]: props.type === "warning",
                [styles.neutral]: props.type === "neutral",
                [styles.being_deleted]: props.beingDeleted,
            })}
        >
            {props.children}
            {props.action && <div onClick={props.action.onClick}>{props.action.label}</div>}
        </div>
    );
};

export default SnackBar;
