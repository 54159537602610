import React from "react";
import icons from "./icons";
import colors from "./colors";
import { IconType } from "./IconType";
import classNames from "classnames";
import styles from "./Icon.module.scss";

type MouseEventListener = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

interface Props {
    // Name of the icon
    name: IconType;
    // Possible id for the wrapper around the svg
    id?: string;
    // Possible classname for the wrapper around the svg
    className?: string;
    // Possible events to add to the icon
    onClick?: MouseEventListener;
    // Possible style-object
    style?: React.CSSProperties;
}

interface IconProps {
    // Color of the icon
    color: string;
}

const DarkBlueIcon: React.FC<Props> = props => <DefaultIcon {...props} color={colors.darkBlue} />;
const LightBlueIcon: React.FC<Props> = props => <DefaultIcon {...props} color={colors.lightBlue} />;
const WhiteIcon: React.FC<Props> = props => <DefaultIcon {...props} color={colors.white} />;
const LightRedIcon: React.FC<Props> = props => <DefaultIcon {...props} color={colors.lightRed} />;
const RedIcon: React.FC<Props> = props => <DefaultIcon {...props} color={colors.red} />;
const OffWhiteIcon: React.FC<Props> = props => <DefaultIcon {...props} color={colors.offWhite} />;

const DefaultIcon: React.FC<Props & IconProps> = props => {
    return (
        <div
            id={props.id}
            className={classNames(props.className, { [styles.clickable]: props.onClick !== undefined })}
            style={props.style}
            onClick={props?.onClick}
        >
            {icons[props.name](props.color)}
        </div>
    );
};

const Icon = {
    LightBlue: LightBlueIcon,
    DarkBlue: DarkBlueIcon,
    White: WhiteIcon,
    LightRed: LightRedIcon,
    RedIcon: RedIcon,
    OffWhite: OffWhiteIcon,
};

export default Icon;
