import React from "react";
import { AdminContextType } from "./AdminContext";

// Actions which the reducer accepts
export enum AdminReducerActions {
    SET_VERIFIED,
    SET_IS_VERIFYING,
    SET_ACTIVE_MENU_ITEM,
    ADD_MENU_ITEM,
}

interface AdminReducerType {
    // Type of action
    type: AdminReducerActions;
    // Payload for the action
    payload: any;
}

const AdminReducer: React.Reducer<AdminContextType, AdminReducerType> = (state, reducer): AdminContextType => {
    switch (reducer.type) {
        case AdminReducerActions.SET_IS_VERIFYING:
            return {
                ...state,
                isVerifying: reducer.payload,
            };
        case AdminReducerActions.SET_VERIFIED:
            return {
                ...state,
                verified: reducer.payload,
            };
        case AdminReducerActions.ADD_MENU_ITEM:
            let copy = state;
            copy.menuItems[reducer.payload.id] = reducer.payload;
            return copy;
        case AdminReducerActions.SET_ACTIVE_MENU_ITEM:
            return {
                ...state,
                activeMenuItem: reducer.payload,
            };
    }
};

export default AdminReducer;
