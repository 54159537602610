import React, { useContext, useState } from "react";
import Page from "../../../ui/Page/Page";
import Header from "../../../ui/Header/Header";
import styles from "./Cart.module.scss";
import usePage from "../../../../contexts/menu/MenuConsumer";
import CartContext from "../../../../contexts/cart/CartContext";
import ApiContext from "../../../../contexts/api/ApiContext";
import ProgressBar from "../../../ui/ProgressBar/ProgressBar";
import { useTranslation } from "react-i18next";
import { formatter } from "../../../../utils/currency";
import { CD } from "../../../../data/interfaces";
import TextInput from "../../../ui/TextInput/TextInput";
import Button from "../../../ui/Button/Button";
import useSnackBar from "../../../../contexts/snackbar/SnackBarConsumer";
import { httpPost } from "../../../../utils/post";
import { PostPayment } from "../../../../data/api-response";
import Icon from "../../../ui/Icon/Icon";

const Base: React.FC = props => {
    return (
        <Page>
            <Header img="001" />
            <div className={styles.wrapper}>{props.children}</div>
        </Page>
    );
};

const Cart: React.FC = () => {
    usePage("cart");
    const { t } = useTranslation("cart");
    const { inCart, removeFromCart } = useContext(CartContext);
    const { cds, apiServer } = useContext(ApiContext);
    const addSnackBar = useSnackBar();
    const [name, setName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [postal, setPostal] = useState<string>();
    const [street, setStreet] = useState<string>();
    const [houseNumber, setHouseNumber] = useState<string>();
    const [place, setPlace] = useState<string>();
    const [confirmed, setConfirmed] = useState(false);
    const [loading, setLoading] = useState(false);

    if (cds.isFetching) {
        return (
            <Base>
                <ProgressBar.Blue visible indeterminate />
            </Base>
        );
    }

    if (!cds.data.success) {
        return <Base>{t("something-went-wrong")}</Base>;
    }

    let mapped: { cd: CD; amount: number }[] = [];
    for (const id of inCart) {
        let found = false;
        for (const alreadyMapped of mapped) {
            if (alreadyMapped.cd.id === id) {
                alreadyMapped.amount += 1;
                found = true;
            }
        }
        if (!found) {
            let cd = undefined;
            for (const cdDetail of cds.data.data.data) {
                if (cdDetail.id === id) cd = cdDetail;
            }
            if (cd) {
                mapped.push({
                    cd: cd,
                    amount: 1,
                });
            } else {
                removeFromCart(id);
            }
        }
    }

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!name || !email || !postal || !street || !houseNumber || !place) {
            addSnackBar(t("required-fields-fail"), "error");
            return;
        }
        setConfirmed(true);
    };

    const pay = () => {
        setLoading(true);
        httpPost<PostPayment>(`${apiServer}/payment`, {
            name: name,
            email: email,
            postal: postal,
            street: street,
            house_number: houseNumber,
            place: place,
            order_ids: inCart,
        }).then(res => {
            setLoading(false);
            if (!res.success) {
                addSnackBar(t("error-creating-payment"), "error");
            } else {
                document.location.href = res.data.data.checkout;
            }
        });
    };

    return (
        <Base>
            {mapped.length === 0 && <i>{t("empty-cart")}</i>}
            {mapped.length !== 0 && (
                <div className={styles.cart}>
                    <div className={styles.column}>
                        {mapped.map(m => (
                            <div key={m.cd.id} className={styles.cd}>
                                <img src={m.cd.image} alt={t("cd-img")} />
                                <div className={styles.details_wrapper}>
                                    <div className={styles.title}>{m.cd.title}</div>
                                    <div className={styles.desc}>{m.cd.description}</div>
                                </div>
                                <Icon.DarkBlue
                                    name="trash"
                                    className={styles.trash}
                                    onClick={() => removeFromCart(m.cd.id)}
                                />
                                <div className={styles.amount}>{m.amount} x</div>
                                <div className={styles.price}>{formatter.format(m.cd.price)}</div>
                            </div>
                        ))}
                        <div className={styles.totals_wrapper}>
                            <div className={styles.cart_wrapper}>
                                <div className={styles.title}>{t("shipping-costs")}</div>
                                <div className={styles.title}>{t("total")}</div>
                            </div>
                            <div className={styles.cart_wrapper}>
                                <div className={styles.amount}>
                                    {formatter.format(
                                        Math.max.apply(
                                            0,
                                            mapped.map(m => m.cd.shipping_costs)
                                        )
                                    )}
                                </div>
                                <div className={styles.amount}>
                                    {formatter.format(
                                        Math.max.apply(
                                            0,
                                            mapped.map(m => m.cd.shipping_costs)
                                        ) + mapped.map(m => m.amount * m.cd.price).reduce((a, b) => a + b)
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.column}>
                        {(!confirmed && (
                            <form onSubmit={onSubmit}>
                                <TextInput onChange={setName} label={t("name")} defaultValue={name} />
                                <TextInput onChange={setEmail} label={t("email")} defaultValue={email} type="email" />
                                <div className={styles.row}>
                                    <TextInput onChange={setStreet} label={t("street")} defaultValue={street} />
                                    <TextInput
                                        onChange={setHouseNumber}
                                        label={t("house-number")}
                                        defaultValue={houseNumber}
                                    />
                                </div>
                                <TextInput
                                    onChange={setPostal}
                                    label={t("postal")}
                                    defaultValue={postal}
                                    pattern="[1-9][0-9]{3}[ ]?(?!ss|sd|sa|sS|sD|sA|Ss|Sd|Sa|SS|SD|SA)[a-zA-Z]{2}"
                                />
                                <TextInput onChange={setPlace} label={t("place")} defaultValue={place} />
                                <Button>{t("proceed")}</Button>
                            </form>
                        )) || (
                            <div className={styles.confirming}>
                                <div className={styles.confirming_wrapper}>
                                    <div className={styles.column}>
                                        <div>{t("name")}</div>
                                        <div>{t("email")}</div>
                                        <div>{t("street")}</div>
                                        <div>{t("place")}</div>
                                    </div>
                                    <div className={styles.column}>
                                        <div>{name}</div>
                                        <div>{email}</div>
                                        <div>
                                            {street} {houseNumber}
                                        </div>
                                        <div>
                                            {postal} {place}
                                        </div>
                                    </div>
                                </div>
                                <Button onClick={() => setConfirmed(false)}>{t("edit")}</Button>
                                <Button loading={loading} onClick={pay}>
                                    {t("pay")}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Base>
    );
};

export default Cart;
