import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import usePage from "../../../../contexts/menu/MenuConsumer";
import Page from "../../../ui/Page/Page";
import Header from "../../../ui/Header/Header";
import ApiContext from "../../../../contexts/api/ApiContext";
import styles from "./Media.module.scss";

const Media: React.FC = () => {
    usePage("media");
    const { t } = useTranslation("media");
    const { videos } = useContext(ApiContext);

    return (
        <Page>
            <Header img="005" />
            <div className={styles.wrapper}>
                {/*<div className={styles.photos}>
                        <div className={styles.title}>{t("press-photos")}</div>
                        {!photos.isFetching && photos.data.success && photos.data.data.data.length === 0 && (
                            <i>{t("no-photos")}</i>
                        )}
                        {!photos.isFetching && photos.data.success && (
                            <div className={styles.photo_wrapper}>
                                {photos.data.data.data.map(p => (
                                    <img key={p.id} src={p.image} alt={t("press-photo")} />
                                ))}
                            </div>
                        )}
                    </div>*/}
                <div className={styles.videos}>
                    <div className={styles.title}>{t("videos")}</div>
                    {!videos.isFetching && videos.data.success && videos.data.data.data.length === 0 && (
                        <i>{t("no-videos")}</i>
                    )}
                    {!videos.isFetching && videos.data.success && (
                        <div className={styles.video_wrapper}>
                            {videos.data.data.data
                                // Sort descending on id so newest are first
                                .sort((a, b) => a.id - b.id)
                                .map(v => (
                                    <iframe
                                        className={styles.video}
                                        key={v.id}
                                        title={v.url}
                                        src={v.url}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    />
                                ))}
                        </div>
                    )}
                </div>
            </div>
        </Page>
    );
};

export default Media;
