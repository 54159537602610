import React, { useContext, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ApiFetchData } from "../../../../data/api";
import { GetPayment } from "../../../../data/api-response";
import { useAsync } from "react-use";
import { httpGet } from "../../../../utils/get";
import ApiContext from "../../../../contexts/api/ApiContext";
import Page from "../../../ui/Page/Page";
import Header from "../../../ui/Header/Header";
import styles from "./CartResult.module.scss";
import ProgressBar from "../../../ui/ProgressBar/ProgressBar";
import { useTranslation } from "react-i18next";
import { Payment } from "../../../../data/interfaces";
import usePage from "../../../../contexts/menu/MenuConsumer";
import CartContext from "../../../../contexts/cart/CartContext";

interface Props {
    // The unique payment-id
    id: string;
}

const PaymentRes: React.FC<Payment> = props => {
    const { t } = useTranslation("cart");
    const { clearCart } = useContext(CartContext);

    let status = props.mollie_payment_status;
    if (status !== "open" && status !== "failed" && status !== "paid") {
        status = "failed";
    }

    useAsync(async () => {
        if (status === "paid") {
            // Remove all items from cart if payment was successful
            clearCart();
        }
    });

    return (
        <>
            <div className={styles.title}>{t(`payment.${status}.title`)}</div>
            <div className={styles.desc}>{t(`payment.${status}.desc`, { email: props.email })}</div>
        </>
    );
};

const CartResult: React.FC<RouteComponentProps<Props>> = props => {
    usePage("cart");
    const [data, setData] = useState<ApiFetchData<GetPayment>>();
    const { apiServer } = useContext(ApiContext);
    const { t } = useTranslation("cart");

    useAsync(async () => {
        const res = await httpGet<GetPayment>(`${apiServer}/payment/${props.match.params.id}`);
        setData(res);
    });

    return (
        <Page>
            <Header img="001" />
            {!data && <ProgressBar.Blue visible indeterminate />}
            <div className={styles.page}>
                <div className={styles.text}>
                    {data && !data.success && <span>{t("payment-not-found")}</span>}
                    {data && data.success && <PaymentRes {...data.data.data} />}
                </div>
            </div>
        </Page>
    );
};

export default CartResult;
