import React from "react";
import styles from "./Button.module.scss";
import classNames from "classnames";
import ProgressBar from "../ProgressBar/ProgressBar";

interface Props {
    // Whether a loading icon should be shown
    loading?: boolean;
    // Function to execute on click
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Button: React.FC<Props> = props => {
    return (
        <button
            className={classNames(styles.button, {
                [styles.loading]: props.loading,
            })}
            onClick={e => {
                if (props.onClick) {
                    e.preventDefault();
                    props.onClick(e);
                }
            }}
        >
            <span className={styles.text}>{props.children}</span>
            <ProgressBar.White visible={props.loading ?? false} indeterminate />
        </button>
    );
};

export default Button;
