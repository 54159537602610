import { ApiFetchData } from "../data/api";
import axios from "axios";

/**
 * Execute HTTP GET-request.
 * @param url   The url to send request to.
 * @param token Optional token to send with the request.
 * @return      Result of the API-fetch.
 */
export async function httpGet<T>(url: string, token?: string): Promise<ApiFetchData<T>> {
    try {
        let headers: { [key: string]: string } = { "Content-Type": "application/json" };
        if (token) headers["Session-Token"] = token;
        const res = await axios.get(url, { headers: headers });
        if (
            res.status >= 200 &&
            res.status < 300 &&
            res.data &&
            (("success" in res.data && res.data.success === true) || !("success" in res.data))
        ) {
            return {
                success: true,
                data: res.data as T,
            };
        } else {
            return {
                success: false,
            };
        }
    } catch (e) {
        return {
            success: false,
        };
    }
}
