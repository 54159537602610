import { createContext } from "react";
import MenuItemType from "./MenuItemType";
import i18n from "../../i18n";

export interface MenuContextType {
    // Key of the active menu-item
    activeMenuItem: string;
    // Set the active menu-item
    setActiveMenuItem: (activeNav: string) => void;
    // Menu-items to show in the menu
    menuItems: { [key: string]: MenuItemType };
    // Add a menu-item
    addMenuItem: (menuItem: MenuItemType) => void;
}

export const initialState: MenuContextType = {
    activeMenuItem: "",
    menuItems: {
        cart: {
            id: "cart",
            route: "/cart",
            name: i18n.t("menu:cart"),
            withCart: true,
        },
    },
    setActiveMenuItem: () => {},
    addMenuItem: () => {},
};

const MenuContext = createContext(initialState);

export default MenuContext;
