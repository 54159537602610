import { createContext } from "react";

export interface SnackBar {
    // Type of the SnackBar
    type: "success" | "error" | "warning" | "neutral";
    // Message to show in the SnackBar
    message: string;
    // Whether SnackBar is in the process of being deleted or not
    beingDeleted: boolean;
    // Possible action for in the SnackBar
    action?: {
        // Text of the action
        label: string;
        // Function to execute when
        // label is clicked
        onClick: () => void;
    };
}

export interface SnackBarContextType {
    snackBar?: SnackBar;
    // Add a SnackBar
    addSnackBar: (
        message: string,
        type: "success" | "error" | "warning" | "neutral",
        action?: { label: string; onClick: () => void }
    ) => void;
}

export const initialContext: SnackBarContextType = {
    addSnackBar: () => {},
};

const SnackBarContext = createContext(initialContext);

export default SnackBarContext;
