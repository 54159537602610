import React from "react";
import styles from "./ProgressBar.module.scss";
import classNames from "classnames";

interface Props {
    // Whether the ProgressBar is visible
    visible: boolean;
    // Whether the ProgressBar is indeterminate
    indeterminate?: boolean;
    // Possible progress (if ProgressBar is determinate (maximum is 100))
    progress?: number;
    // Optional classnames to add
    className?: string;
}

interface Determinate {
    progress: number;
}

interface Indeterminate {
    indeterminate: true;
}

type ProgressBarColor = "blue" | "white" | "lightRed";

type ProgressBarProps = Props & (Determinate | Indeterminate);

const ProgressBar: React.FC<ProgressBarProps & { color: ProgressBarColor }> = props => {
    return (
        <div
            className={classNames(styles.loader_wrapper, props.className, {
                [styles.loading]: props.visible,
                [styles.blue]: props.color === "blue",
                [styles.white]: props.color === "white",
                [styles.red]: props.color === "lightRed",
            })}
        >
            <div className={styles.loader_bg}>
                <div
                    className={classNames(styles.loader_indicator, {
                        [styles.indeterminate]: props.indeterminate,
                        [styles.determinate]: !props.indeterminate,
                    })}
                    style={props.progress ? { right: `${100 - props.progress}%` } : undefined}
                />
            </div>
        </div>
    );
};

const BlueProgressBar: React.FC<ProgressBarProps> = props => <ProgressBar {...props} color="blue" />;
const WhiteProgressBar: React.FC<ProgressBarProps> = props => <ProgressBar {...props} color="white" />;
const RedProgressBar: React.FC<ProgressBarProps> = props => <ProgressBar {...props} color="lightRed" />;

const ProgressBars = {
    Blue: BlueProgressBar,
    White: WhiteProgressBar,
    Red: RedProgressBar,
};

export default ProgressBars;
