import React, { RefObject, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import usePage from "../../../../contexts/menu/MenuConsumer";
import Page from "../../../ui/Page/Page";
import Header from "../../../ui/Header/Header";
import styles from "./Agenda.module.scss";
import Icon from "../../../ui/Icon/Icon";
import AgendaItems from "./AgendaItems";
import ApiContext from "../../../../contexts/api/ApiContext";
import { Event } from "../../../../data/interfaces";
import { containsSubstring } from "../../../../utils/strings";

interface Props {
    onChange: (input: string) => void;
    placeholder: string;
    wrapper: RefObject<HTMLDivElement>;
}

const Input: React.FC<Props> = props => {
    /**
     * Remove focused class from wrapper if one of the inputs un-focuses.
     */
    const onFocus = () => {
        if (props.wrapper.current) {
            props.wrapper.current.classList.add(styles.focused);
        }
    };

    /**
     * Add focused class to the wrapper if one of the inputs focuses.
     */
    const onBlur = () => {
        if (props.wrapper.current) {
            props.wrapper.current.classList.remove(styles.focused);
        }
    };
    return (
        <input
            type="text"
            placeholder={props.placeholder}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={e => props.onChange(e.target.value)}
        />
    );
};

const Agenda: React.FC = () => {
    usePage("agenda");
    const wrapper = useRef<HTMLDivElement>(null);
    const { t } = useTranslation("agenda");
    const [eventsToDisplay, setEvents] = useState<Event[]>([]);
    const { events } = useContext(ApiContext);
    const [search, setSearch] = useState<string>();
    const [location, setLocation] = useState<string>();
    const [year, setYear] = useState<string>();

    useEffect(() => {
        if (events.isFetching || !events.data.success) return;
        let eventData = events.data.data.data;
        if (search) {
            eventData = eventData.filter(
                e =>
                    containsSubstring(e.title, search) ||
                    containsSubstring(e.description, search) ||
                    containsSubstring(e.location, search)
            );
        }
        if (location) {
            eventData = eventData.filter(e => containsSubstring(e.location, location));
        }
        if (year) {
            // Show events from history if user searches
            eventData = eventData.filter(e => containsSubstring(String(new Date(e.datetime_raw).getFullYear()), year));
        } else {
            // Show only events from future if user doesn't search on year
            eventData = eventData.filter(e => new Date(e.datetime_raw).getTime() - new Date().getTime() > 0);
        }
        eventData = eventData.sort((a, b) => new Date(a.datetime_raw).getTime() - new Date(b.datetime_raw).getTime());
        setEvents(eventData);
    }, [events, search, location, year]);

    return (
        <Page>
            <Header img="005" />
            <div className={styles.page}>
                <div className={styles.search} ref={wrapper}>
                    <div className={styles.input}>
                        <Icon.DarkBlue name="search" className={styles.img} />
                        <Input placeholder={t("search")} onChange={setSearch} wrapper={wrapper} />
                    </div>
                    <div className={styles.input}>
                        <Icon.DarkBlue name="location" className={styles.img} />
                        <Input placeholder={t("location")} onChange={setLocation} wrapper={wrapper} />
                    </div>
                    <div className={styles.input}>
                        <Icon.DarkBlue name="calendar" className={styles.img} />
                        <Input placeholder={t("year")} onChange={setYear} wrapper={wrapper} />
                    </div>
                    <button className={styles.button}>{t("search")}</button>
                </div>
                <div className={styles.events}>
                    <AgendaItems
                        isFetching={events.isFetching}
                        success={events.isFetching || events.data.success}
                        events={eventsToDisplay}
                    />
                </div>
            </div>
        </Page>
    );
};

export default Agenda;
