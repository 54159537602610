import React, { useContext, useState } from "react";
import AdminContext from "../../../../contexts/admin/AdminContext";
import TextInput from "../../../ui/TextInput/TextInput";
import useSnackBar from "../../../../contexts/snackbar/SnackBarConsumer";
import Button from "../../../ui/Button/Button";
import { Redirect } from "react-router-dom";
import styles from "./Login.module.scss";
import { useTranslation } from "react-i18next";

const Login: React.FC = () => {
    const { signIn, verified } = useContext(AdminContext);
    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();
    const addSnackBar = useSnackBar();
    const { t } = useTranslation("adminLogin");

    if (verified) {
        return <Redirect to="/" />;
    }

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!username || !password) {
            addSnackBar(t("fill-fields"), "error");
        } else {
            const res = await signIn(username, password);
            if (!res) addSnackBar(t("incorrect-credentials"), "error");
        }
    };

    return (
        <div className={styles.wrapper}>
            <form onSubmit={onSubmit}>
                <TextInput label={t("username")} onChange={setUsername} autoFocus />
                <TextInput label={t("password")} onChange={setPassword} type="password" />
                <Button>{t("sign-in")}</Button>
            </form>
        </div>
    );
};

export default Login;
