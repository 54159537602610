import React, { useContext, useState } from "react";
import Page from "../../ui/Page/Page";
import useAdminPage from "../../../../contexts/admin/AdminMenuConsumer";
import { ApiFetchData } from "../../../../data/api";
import { GetPages } from "../../../../data/api-response";
import { useAsync, useUpdate } from "react-use";
import { httpGet } from "../../../../utils/get";
import Loading from "../../general/Loading/Loading";
import useSnackBar from "../../../../contexts/snackbar/SnackBarConsumer";
import ApiContext from "../../../../contexts/api/ApiContext";
import { useTranslation } from "react-i18next";
import TextArea from "../../../ui/TextArea/TextArea";
import { Page as PageData } from "../../../../data/interfaces";
import Button from "../../../ui/Button/Button";
import { httpPost } from "../../../../utils/post";
import AdminContext from "../../../../contexts/admin/AdminContext";

const Pages: React.FC = () => {
    useAdminPage("pages");
    const [updatedPages, setUpdatedPages] = useState<{ [alias: string]: { de: string; en: string; nl: string } }>();
    const [pages, setPages] = useState<ApiFetchData<GetPages>>();
    const { apiServer } = useContext(ApiContext);
    const { t } = useTranslation("adminPages");
    const addSnackBar = useSnackBar();
    const update = useUpdate();
    const { token } = useContext(AdminContext);

    useAsync(async () => {
        setPages(await httpGet(`${apiServer}/page`));
    });

    if (!pages) {
        return <Loading />;
    }

    if (!pages.success) {
        return <div>{t("adminGeneral:error")}</div>;
    }

    const updatePage = (p: PageData, lang: "de" | "en" | "nl", content: string) => {
        if (updatedPages && updatedPages[p.alias]) {
            let copy = updatedPages;
            copy[p.alias][lang] = content;
            setUpdatedPages(copy);
        } else {
            let initial = {
                de: p.content_de,
                en: p.content_en,
                nl: p.content_nl,
            };
            initial[lang] = content;
            let update = updatedPages ?? {};
            update[p.alias] = initial;
            setUpdatedPages(update);
        }
        update();
    };

    const isEdited = (p: PageData) => updatedPages?.hasOwnProperty(p.alias);

    const cancelEditing = (p: PageData) => {
        let copy = updatedPages;
        if (copy) {
            delete copy[p.alias];
            setUpdatedPages(copy);
            update();
        }
    };

    const getValue = (p: PageData, lang: "de" | "en" | "nl") => {
        if (isEdited(p)) {
            return updatedPages!![p.alias][lang];
        } else {
            switch (lang) {
                case "de":
                    return p.content_de;
                case "en":
                    return p.content_en;
                case "nl":
                    return p.content_nl;
            }
        }
    };

    const savePage = async (p: PageData) => {
        if (updatedPages && updatedPages[p.alias]) {
            const updatedPage = updatedPages[p.alias];
            let updateToSend: { [key: string]: string } = {};
            updateToSend.content_de = updatedPage.de;
            updateToSend.content_en = updatedPage.en;
            updateToSend.content_nl = updatedPage.nl;
            httpPost(`${apiServer}/page/${p.alias}`, updateToSend, token).then(res => {
                if (res.success) {
                    addSnackBar(t("adminGeneral:updated"), "success");
                    let copyPages = pages;
                    copyPages.data.data = copyPages.data.data.map(page => {
                        if (page.alias === p.alias) {
                            return {
                                id: p.id,
                                alias: p.alias,
                                content_de: updateToSend.content_de as string,
                                content_en: updateToSend.content_en as string,
                                content_nl: updateToSend.content_nl as string,
                            };
                        }
                        return page;
                    });
                    setPages(copyPages);
                    cancelEditing(p);
                } else {
                    addSnackBar(t("adminGeneral:not-updated"), "error");
                }
            });
        } else {
            addSnackBar(t("adminGeneral:not-updated"), "error");
        }
    };

    return (
        <Page>
            {pages.data.data.map(p => (
                <form key={p.id}>
                    <h4>{t(`menu:${p.alias}`)}</h4>
                    <TextArea
                        label={t("content-de")}
                        onChange={val => updatePage(p, "de", val)}
                        defaultValue={p.content_de}
                        value={getValue(p, "de")}
                    />
                    <TextArea
                        label={t("content-en")}
                        onChange={val => updatePage(p, "en", val)}
                        defaultValue={p.content_en}
                        value={getValue(p, "en")}
                    />
                    <TextArea
                        label={t("content-nl")}
                        onChange={val => updatePage(p, "nl", val)}
                        defaultValue={p.content_nl}
                        value={getValue(p, "nl")}
                    />
                    {isEdited(p) && (
                        <>
                            <Button onClick={() => cancelEditing(p)}>{t("adminGeneral:cancel")}</Button>
                            <Button onClick={() => savePage(p)}>{t("adminGeneral:save-changes")}</Button>
                        </>
                    )}
                </form>
            ))}
        </Page>
    );
};

export default Pages;
