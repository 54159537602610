import React, { useCallback, useMemo, useReducer } from "react";
import NavigationReducer, { MenuReducerActions } from "./MenuReducer";
import MenuContext, { initialState } from "./MenuContext";
import MenuItemType from "./MenuItemType";

export const MenuProvider: React.FC = props => {
    const [state, dispatch] = useReducer(NavigationReducer, initialState);

    /**
     * Set the active menu item.
     * @param activeMenuItem The id of the item to set to active.
     */
    const setActiveMenuItem = useCallback((activeMenuItem: string) => {
        dispatch({
            type: MenuReducerActions.SET_ACTIVE_MENU_ITEM,
            payload: activeMenuItem,
        });
    }, []);

    /**
     * Add a menu-item.
     * @param menuItem The menu-item to add.
     */
    const addMenuItem = useCallback((menuItem: MenuItemType) => {
        dispatch({
            type: MenuReducerActions.ADD_MENU_ITEM,
            payload: menuItem,
        });
    }, []);

    const menuContextValue = useMemo(() => {
        return {
            menuItems: state.menuItems,
            activeMenuItem: state.activeMenuItem,
            setActiveMenuItem: setActiveMenuItem,
            addMenuItem: addMenuItem,
        };
    }, [state, setActiveMenuItem, addMenuItem]);

    return <MenuContext.Provider value={menuContextValue}>{props.children}</MenuContext.Provider>;
};
