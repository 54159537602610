import React, { useCallback, useMemo, useReducer } from "react";
import CartContext, { initialContext, CartContextType } from "./CartContext";
import CartReducer, { CartReducerActions } from "./CartReducer";
import { useAsync, useLocalStorage } from "react-use";

const CartProvider: React.FC = props => {
    const [state, dispatch] = useReducer(CartReducer, initialContext);
    const [cache, setCache] = useLocalStorage<number[]>("shopping-cart");

    /**
     * Add an id of a cd to the shopping-cart.
     * @param id      The id of the cd to place in the shopping-cart.
     * @param toCache Whether CDs should be added to the cache as well. Default true.
     */
    const addToCart = useCallback(
        (id: number, toCache?: boolean) => {
            dispatch({
                type: CartReducerActions.ADD_TO_CART,
                payload: id,
            });
            if (toCache !== false) {
                setCache([...(cache ?? []), id]);
            }
        },
        [setCache, cache]
    );

    /**
     * Remove an element from the shopping-cart.
     * @param id The id of the CDs to remove.
     */
    const removeFromCart = useCallback(
        (id: number) => {
            let copy = cache;
            if (copy) {
                const index = copy.indexOf(id);
                if (index !== -1) {
                    copy.splice(index, 1);
                }
                setCache(copy);
                dispatch({
                    type: CartReducerActions.SET_CART,
                    payload: copy,
                });
            }
        },
        [cache, setCache]
    );

    /**
     * Clear all items from the shopping-cart.
     */
    const clearCart = useCallback(() => {
        dispatch({
            type: CartReducerActions.CLEAR_CART,
            payload: undefined,
        });
        setCache([]);
    }, [setCache]);

    useAsync(async () => {
        // Initialize the state with the cache
        if (cache) {
            for (const id of cache) {
                addToCart(id, false);
            }
        }
    });

    const contextValue = useMemo((): CartContextType => {
        return {
            inCart: state.inCart,
            addToCart: addToCart,
            removeFromCart: removeFromCart,
            clearCart: clearCart,
        };
    }, [state.inCart, addToCart, removeFromCart, clearCart]);

    return <CartContext.Provider value={contextValue}>{props.children}</CartContext.Provider>;
};

export default CartProvider;
