import React from "react";
import { SnackBar, SnackBarContextType } from "./SnackBarContext";

export enum SnackBarReducerActions {
    DELETE_SNACKBAR,
    ADD_SNACKBAR,
    START_DELETION,
}

interface SnackBarReducerType {
    // Type of action
    type: SnackBarReducerActions;
    // Payload of the action (only needed for ADD_SNACKBAR)
    payload?: SnackBar;
}

const SnackBarReducer: React.Reducer<SnackBarContextType, SnackBarReducerType> = (state, reducer): SnackBarContextType => {
    switch (reducer.type) {
        case SnackBarReducerActions.ADD_SNACKBAR:
            return {
                ...state,
                snackBar: reducer.payload,
            };
        case SnackBarReducerActions.DELETE_SNACKBAR:
            return {
                ...state,
                snackBar: undefined,
            };
        case SnackBarReducerActions.START_DELETION:
            if (state.snackBar) {
                return {
                    ...state,
                    snackBar: {
                        ...state.snackBar,
                        beingDeleted: true,
                    },
                };
            }
            return state;
    }
};

export default SnackBarReducer;
