import { createContext } from "react";
import AdminMenuItemType from "./AdminMenuType";

export interface AdminContextType {
    verified: boolean;
    setVerified: (verified: boolean) => void;
    isVerifying: boolean;
    setIsVerifying: (verifying: boolean) => void;
    signIn: (username: string, password: string) => Promise<boolean>;
    signOut: () => void;

    token: string;

    // Key of the active menu-item
    activeMenuItem: string;
    // Set the active menu-item
    setActiveMenuItem: (activeNav: string) => void;
    // Menu-items to show in the menu
    menuItems: { [key: string]: AdminMenuItemType };
    // Add a menu-item
    addMenuItem: (menuItem: AdminMenuItemType) => void;
}

export const initialContext: AdminContextType = {
    verified: false,
    setVerified: () => {},
    isVerifying: true,
    setIsVerifying: () => {},
    signIn: () => new Promise(r => r(false)),
    signOut: () => {},

    token: "",

    activeMenuItem: "",
    setActiveMenuItem: () => {},
    menuItems: {},
    addMenuItem: () => {},
};

const AdminContext = createContext(initialContext);

export default AdminContext;
