import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AdminContext from "../../../../contexts/admin/AdminContext";
import Loading from "../Loading/Loading";
import CDs from "../../pages/CDs/CDs";
import Menu from "../Menu/Menu";
import Orders from "../../pages/Orders/Orders";
import Events from "../../pages/Events/Events";
import Media from "../../pages/Media/Media";
import Pages from "../../pages/Pages/Pages";

const Dashboard: React.FC = () => {
    const { verified, isVerifying } = useContext(AdminContext);

    if (isVerifying) {
        return <Loading />;
    }

    if (!verified) {
        return <Redirect to="/login" />;
    }

    return (
        <>
            <Menu />
            <Switch>
                <Route path="/cds" component={CDs} />
                <Route path="/orders" component={Orders} />
                <Route path="/media" component={Media} />
                <Route path="/pages" component={Pages} />
                <Route path="/" component={Events} />
            </Switch>
        </>
    );
};

export default Dashboard;
