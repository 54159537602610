import React, { useContext } from "react";
import styles from "./Menu.module.scss";
import MenuItem from "./MenuItem";
import { useTranslation } from "react-i18next";
import AdminContext from "../../../../contexts/admin/AdminContext";

const Menu: React.FC = () => {
    const { signOut } = useContext(AdminContext);
    const { t } = useTranslation("adminMenu");

    return (
        <nav className={styles.nav}>
            <ul>
                <MenuItem id="events" route="/">
                    {t("events")}
                </MenuItem>
                <MenuItem id="cds" route="/cds">
                    {t("cds")}
                </MenuItem>
                <MenuItem id="orders" route="/orders">
                    {t("orders")}
                </MenuItem>
                <MenuItem id="media" route="/media">
                    {t("media")}
                </MenuItem>
                <MenuItem id="pages" route="/pages">
                    {t("pages")}
                </MenuItem>
                <li className={styles.sign_out} onClick={signOut}>
                    <span>{t("sign-out")}</span>
                </li>
            </ul>
        </nav>
    );
};

export default Menu;
