import React from "react";
import classNames from "classnames";
import styles from "./Text.module.scss";

interface Image {
    src: string;
    alt: string;
    extra?: string;
}

interface Props {
    // Optional className to add to the wrapper
    className?: string;
    // Image to show left of the text
    leftImg?: Image;
    // Image to show right of the text
    rightImg?: Image;
}

const Text: React.FC<Props> = props => {
    return (
        <main
            className={classNames(props.className, styles.wrapper, {
                [styles.with_img]: (props.leftImg || props.rightImg) && !(props.rightImg && props.leftImg),
                [styles.with_double_img]: props.leftImg && props.rightImg,
            })}
        >
            {props.leftImg && (
                <div className={styles.img_wrapper}>
                    <img src={props.leftImg.src} alt={props.leftImg.alt} />
                </div>
            )}
            <p>{props.children}</p>
            {props.rightImg && (
                <div className={styles.img_wrapper}>
                    <img src={props.rightImg.src} alt={props.rightImg.alt} />
                    {props.rightImg.extra && <i>{props.rightImg.extra}</i>}
                </div>
            )}
        </main>
    );
};

export default Text;
